.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
ul.pagination li:nth-child(n+8):nth-last-child(n+8){
    display:none;
}
.card-body table {
    font-size: 12px !important;
}
.logo-auth{
  color: #FFF;
  font-size: 40px;
  font-weight: 100 !important;
}
.img-grid-container{
  width: 100%;
  max-width: 100%;
  height: 200px;
  display: table-cell;
  vertical-align: middle;
}
.img-grid{
  max-width: 300px;
  object-fit: contain;
  object-fit: contain;
  width: 100%;
}

.product-miniature{
  font-size: 12px;
}
  .product-miniature h5{
    font-size: 13px;
    text-transform: uppercase;
    height: 32px;
    overflow: hidden;
  }
  .product-miniature p:nth-child(1){}
  .product-miniature p:nth-child(2){}
  .product-miniature p:nth-child(3){}
  p.card-text.lead.fw-bold {
    font-size: 12px;
  }
  .card.border-left-success.shadow.h-100 table tr {
    border-bottom: 1px solid #f1f1f1;
  }

  .card.border-left-success.shadow.h-100 table tr td {
    padding: 10px;
  }
  .container-xl.px-4.mt-n10{
    max-width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  span.sidebar-logout {
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: 10px;
    padding: 6px;
  }
.btn-block{
  display: block;
  width: 100%;
}
.text-center{
  text-align: center;
}
.listPrinterCounter{
  display: block;
  text-align: center;
  width: 100%;
}
span.totalPrinter {
    padding-left: 10px;
}
span.currentPrinter {
    padding-right: 10px;
}